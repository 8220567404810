/* eslint-disable no-alert */

import React, { useState } from 'react'

import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { t } from '@lingui/macro'

import * as yup from 'yup'
import dayjs from 'dayjs'

import { useDispatch, useSelector } from 'react-redux'
import { channelSelector } from '../../../../../redux-store/channel/channel.selectors'
import { postProductType } from '../../../../../api/channel/settings/postProductType'

import { DropdownList } from '../../../../../components/Forms/DropdownListField/DropdownList'
import { ProductTypeID, ProductTypeList } from './ProductTypes'
import { SubmitButton } from '../../../../../components/buttons/SubmitButton/SubmitButton'
import styles from './BrandingHeader.module.scss'
import { ReleaseDateModal } from '../ReleaseDate/ReleaseDateModal'
import channelsActions from 'redux-store/channel/channels.actions'
import EditIcon from 'assets/icons/EditIcon'
import { FormValidationError } from 'components/Forms/FormValidationError/FormValidationError'
interface ChannelProductTypeValues {
  productType: string
  date: number
}
export const ProductVariantField = () => {
  const channel = useSelector(channelSelector)
  const dispatch = useDispatch()
  const channelProductType = channel?.data?.productVariant
  const channelSlug = channel?.data?.subdomain
  const releaseDate = channel?.data?.release_date
  const [showReleaseDateModal, setShowReleaseDateModal] = useState(false)

  const initialValues: ChannelProductTypeValues = {
    productType: channelProductType?.id,
    date: releaseDate
  }
  const channelProductTypeSchema = yup.object({
    productType: yup.string().required(),
    releaseDate: yup.number().nullable()
  })

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    formikHelpers.setSubmitting(true)
    const params = { productVariantID: values.productType, channelSlug }
    if (values.productType === ProductTypeID.Default) {
      if (
        !window.confirm(
          'Are you sure that you would like to change this channel to become a default channel.'
        )
      ) {
        formikHelpers.setSubmitting(false)
      }
    }

    const response = await postProductType(params)
    if (response.hasError) {
      formikHelpers.setErrors(response.errors)
    } else {
      dispatch(channelsActions.updateProductType(values.productType))
    }
    formikHelpers.setSubmitting(false)
  }

  const getReleaseDateText = () => {
    if (releaseDate) {
      return dayjs.unix(releaseDate).format('YYYY-MM-DD HH:mm')
    }
    return t`No date configured`
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={channelProductTypeSchema}
      validateOnChange={true}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<any>) => {
        const hasProductTypeChanged =
          props.values.productType !== initialValues.productType

        const isSubmitDisabled =
          !hasProductTypeChanged ||
          !(
            props.values.productType === '0' ||
            (props.values.productType === '2' && props.values.date)
          ) ||
          !props.isValid ||
          props.isSubmitting

        return (
          <Form>
            <div className={styles.DropdownFieldWrapper}>
              <Field
                component={DropdownList}
                dropdownListData={ProductTypeList}
                value={props.values.productType}
                id="productType"
                name="productType"
                label={t`System variant`}
                placeholder={t`please select a system variant`}
                isValid={!props.errors.productType}
                validationMessage={props.errors.productType}
                type="dark"
                onChange={(id: string) =>
                  props.setFieldValue('productType', id)
                }
              />
            </div>
            {props.values.productType === '2' && (
              <div className={styles.ReleaseDateContainer}>
                <div className={styles.Label}>Release date:</div>
                <div
                  className={styles.Date}
                  onClick={() => setShowReleaseDateModal(true)}
                >
                  {getReleaseDateText()}
                  <div className={styles.Icon}>
                    <EditIcon />
                  </div>
                </div>
                {!props.values.date && (
                  <FormValidationError
                    errorMessage={t`Set a release date to be able to continue`}
                  />
                )}
              </div>
            )}

            <SubmitButton
              type="secondary"
              customStyle={styles.SubmitButton}
              disabled={isSubmitDisabled}
              label={t`Save changes`}
            />
            <ReleaseDateModal
              defaultOpen={showReleaseDateModal}
              onClose={() => setShowReleaseDateModal(false)}
              onSuccess={(newReleaseDate: number) =>
                props.setFieldValue('date', newReleaseDate)
              }
            />
          </Form>
        )
      }}
    </Formik>
  )
}
