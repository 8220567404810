import React, { useEffect, useState, useCallback, StrictMode } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { validateToken } from '../network/ApiEndpoint'
import useAuthentication from '../hooks/Authentication'
import { LoggingInLoadingOverlay } from '../components/LoggingInLoadingOverlay/LoggingInLoadingOverlay'
import useCompanyParam from '../hooks/CompanyParam'
import useCurrentUser from '../hooks/CurrentUser'
import { UserSessionContainer } from '../components/session/UserSessionContainer/UserSessionContainer'
import {
  getCurrentLocale,
  locales,
  activateDateSettings
} from '../translations/i18n'
import { languageSelector } from '../redux-store/language/language.selectors'

import { NavigationHeaderPreloader } from 'components/page/header/components/NavigationHeaderPreloader'
import { AppHeader } from './AppHeader'
import { AppRoutes } from './AppRoutes'
import { getChannel } from '../api/channel/getChannel'
import channelsActions from '../redux-store/channel/channels.actions'
import { useStateDataApi } from '../api/useStateDataApi'
import ComingSoonPage from '../pages/ComingSoonPage/ComingSoonPage'
import { getIsSpecialDomain } from '../getIsSpecialDomain'
import { setCustomTheme } from '../redux-store/channelTheme/channelTheme.actions'
import { getFontsForChannel } from '../api/channel/font/getFontsForChannel'
import {
  getChannelFonts,
  getChannelFontsError,
  getChannelFontsSuccess
} from '../redux-store/channelFonts/channelFonts.slice'
import { useRelevantTheme } from './useRelevantTheme'
import { Loader } from '../components/Loader/Loader'
import { useChannelTheme } from '../pages/ChannelPage/Theme/useChannelTheme'
import { useChannelDataStatus } from '../pages/ChannelPage/useChannelDataStatus'
import { channelSelector } from '../redux-store/channel/channel.selectors'
import authenticationActions from '../redux-store/authentication/authentication.actions'
import userActions from '../redux-store/user/user.actions'
import { useFeatureFlags } from '../featureFlags/useFeatureFlags'
import { FeatureFlagsAvailable } from '../featureFlags/FeatureFlagsAvailable'
import { useComingSoon } from './comingSoon/useComingSoon'
import { checkReleaseDate } from './comingSoon/checkReleaseDate'
import { ComingSoon } from './comingSoon/ComingSoon'
import { Footer } from 'components/page/footer/Footer'
import { getChannelAbout } from 'api/channel/getChannelAbout'
import {
  getChannelAboutError,
  getChannelAboutRequest,
  getChannelAboutSuccess
} from 'redux-store/channelAbout/channelAbout.slice'

import styles from './solidsport.module.scss'
import './App.scss'
import { PaymentContainer } from 'components/payment/PaymentContainer/PaymentContainer'
import { useFavIcon } from 'pages/ChannelPage/useFavIcon'
import languageActions from 'redux-store/language/language.actions'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import { locationActions } from 'redux-store/location/location.action'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'

const App: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const company = useCompanyParam()
  const channel = useSelector(channelSelector)
  const { channelThemeIsLoading } = useChannelTheme()
  const {
    channelDataIsReady,
    channelDataNotFound,
    channelShouldChange,
    channelDataExpected
  } = useChannelDataStatus(channel, company, () =>
    dispatch(channelsActions.setShouldChange)
  )
  useFavIcon({ faviconUrl: channel?.data?.favicon_url })
  const isViewingStream = useSelector(isViewingStreamSelector)

  const { setFlag, getFlag } = useFeatureFlags()

  const currentLanguage = useSelector(languageSelector)
  const currentUser = useCurrentUser()
  const isBrowsePage =
    location.pathname === '/browse' || location.pathname === '/'
  const isBroadcastPage = location.pathname === '/broadcast'
  const showBasicHeader = location.pathname === '/landing'
  const isViewerPage = /\/(g|watch)\//.test(location.pathname)
  const showAccountHeader = location.pathname.includes('/user/profile')

  const addTopSpacer =
    !isBrowsePage && !showBasicHeader && !isBroadcastPage && !showAccountHeader
  const [locale] = useState(currentUser?.locale || getCurrentLocale())
  const [isChildOfThemedRoot, setIsChildOfThemedRoot] = useState(false)
  const initialComingSoonAttributes: ComingSoon = {
    logoUrl: undefined,
    backgroundImageUrl: undefined,
    vanityUrl: undefined,
    channelSlug: undefined
  }
  const [comingSoonAttributes, setComingSoonAttributes] = useState(
    initialComingSoonAttributes
  )
  useEffect(() => {
    if (isViewingStream && !isViewerPage) {
      dispatch(userInterface.closeViewStream())
    }
  }, [isViewerPage, isViewingStream])

  const [parentReleaseDate, setParentReleaseDate] = useState<
    number | undefined
  >(undefined)

  const [showLoading, setShowLoading] = useState<boolean>(
    channelShouldChange || true
  )
  const { loggingIn } = useAuthentication()

  useEffect(() => {
    validateToken()
      .then((isValid) => {
        if (isValid) {
          dispatch(authenticationActions.resetFromLocalState())
          dispatch(userActions.resetFromLocalState())
        }
      })
      .catch(() => {
        console.log('Error validating token')
      })
  }, [])

  const wrappedGetChannel = useCallback(getChannel, [])
  useStateDataApi({
    actions: {
      start: channelsActions.getChannel,
      success: channelsActions.channelCompanyActionCreator,
      error: channelsActions.getChannelError
    },
    apiCall: wrappedGetChannel,
    parameters: company
  })

  const wrappedGetAboutChannel = useCallback(getChannelAbout, [
    channelDataIsReady,
    company
  ])
  useStateDataApi({
    actions: {
      start: getChannelAboutRequest,
      success: getChannelAboutSuccess,
      error: getChannelAboutError
    },
    apiCall: wrappedGetAboutChannel,
    parameters: channel?.data?.parent_channel_subdomain || company
  })

  useEffect(() => {
    if (
      (channelDataIsReady && !channelThemeIsLoading && !channelShouldChange) ||
      channelDataNotFound
    ) {
      setShowLoading(false)
    } else {
      setShowLoading(true)
    }
  }, [
    channelDataNotFound,
    channelDataIsReady,
    channelThemeIsLoading,
    channelShouldChange
  ])
  useEffect(() => {
    if (!currentLanguage) {
      dispatch(languageActions.setLanguage(locale))
    }
  }, [locale])
  useEffect(() => {
    if (location) {
      dispatch(locationActions.setLocation({ pathName: location.pathname }))
    }
  }, [location])
  // eslint-disable-next-line no-unneeded-ternary
  const makeFontApiCall = channel?.data?.is_root_channel ? true : false
  const wrappedGetChannelFonts = useCallback(getFontsForChannel, [])
  useStateDataApi({
    actions: {
      start: getChannelFonts,
      success: getChannelFontsSuccess,
      error: getChannelFontsError
    },
    apiCall: wrappedGetChannelFonts,
    parameters: company,
    makeCall: makeFontApiCall
  })

  checkReleaseDate(
    channel,
    parentReleaseDate,
    setParentReleaseDate,
    setComingSoonAttributes
  )

  const isRootOrChild = channel?.data?.isRootChannel || isChildOfThemedRoot
  const now = Math.floor(Date.now() / 1000)

  useRelevantTheme({
    company,
    dispatch,
    channel,
    setParentReleaseDate,
    setComingSoonAttributes,
    setIsChildOfThemedRoot,
    setCustomTheme
  })

  useEffect(() => {
    activateDateSettings(locale)
  }, [currentUser, currentLanguage])

  const releaseDate = parentReleaseDate
  const releaseDateHasNotPassed = releaseDate && releaseDate > now

  const { hostname } = window.location
  const isSpecialDomain = getIsSpecialDomain(hostname)
  const hideComingSoonPage = getFlag(FeatureFlagsAvailable.HideComingSoon)

  useComingSoon(
    releaseDate,
    releaseDateHasNotPassed,
    isSpecialDomain,
    hideComingSoonPage,
    setFlag,
    channel
  )
  const queryClient = new QueryClient()

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    if (urlSearchParams.get('open_auth_popup') === 'register') {
      dispatch(authenticationActions.openLoginLayer(undefined, 'signup'))
    }
  }, [])
  const comingSoon = getFlag(FeatureFlagsAvailable.ComingSoon)
  const companyName = 'Solidsport AB'

  return (
    <div className={classNames('SolidSport', styles.SolidSport)}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          {comingSoon ? (
            <ComingSoonPage
              releaseDate={releaseDate!}
              backgroundImageUrl={comingSoonAttributes.backgroundImageUrl!}
              logoImageUrl={comingSoonAttributes.logoUrl!}
              vanityUrl={comingSoonAttributes.vanityUrl!}
              channelSlug={comingSoonAttributes.channelSlug!}
            />
          ) : (
            <>
              <LoggingInLoadingOverlay loggingIn={loggingIn} />
              {showLoading ? (
                <NavigationHeaderPreloader />
              ) : (
                <AppHeader
                  showAccountHeader={showAccountHeader}
                  showBasicHeader={showBasicHeader}
                  addTopSpacer={addTopSpacer}
                  isRootOrChild={isRootOrChild}
                  isViewingStream={isViewingStream}
                  isRootClubChannel={channel?.data?.isRootClubChannel}
                />
              )}
              <div className={styles.Main}>
                {showLoading ? (
                  <Loader />
                ) : (
                  <StrictMode>
                    <AppRoutes
                      channelDataIsReady={channelDataIsReady}
                      channelDataExpected={channelDataExpected}
                      channelDataNotFound={channelDataNotFound}
                    />
                  </StrictMode>
                )}
              </div>
              <UserSessionContainer />
              {!isViewingStream && !showAccountHeader && (
                <Footer
                  isRootOrChild={isRootOrChild}
                  channelDataIsReady={channelDataIsReady}
                  companyName={companyName}
                  locales={locales}
                />
              )}
              <PaymentContainer />
            </>
          )}
        </HelmetProvider>
      </QueryClientProvider>
    </div>
  )
}
export default App
