import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

interface Props {
  productVariantID: string
  channelSlug: string
}
interface ProductTypeResponse {
  hasError: boolean
  errors?: any
}
export const postProductType = async (
  params: Props
): Promise<ProductTypeResponse> => {
  try {
    const formData = new FormData()
    formData.append('channel_variant', params.productVariantID)
    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${params.channelSlug}/launch_pannel`,
      method: ApiEndpoint.METHODS.POST,
      file: formData,
      authenticatable: true
    })
    await response
    return { hasError: false }
  } catch (error: any) {
    console.error('error!', error)
    return {
      hasError: true,
      errors: [error]
    }
  }
}
