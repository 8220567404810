import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'
import { RedirectApiResponse } from './RedirectApiResponse'

export const getRedirect = async (
  domain: string,
  currentUri: string
): Promise<RedirectApiResponse | undefined> => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'get_redirect',
      params: {
        company: domain,
        current_uri: currentUri
        // cb: Math.floor(Math.random() * 1000) /// cache buster  -> remove before committing to master
      }
    })
    const responseData = (await resultResponse.data) as
      | RedirectApiResponse
      | undefined

    return responseData
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
