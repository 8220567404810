import { ApiStatus } from 'pages/ChannelPage/ChannelSettings/Branding/Logos/UploadLogo/ApiStatus.interface'
import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'
import { t } from '@lingui/macro'

interface Props {
  releaseDateAndTime: number
  channelSlug: string
}

export const postReleaseDate = async (params: Props): Promise<ApiStatus> => {
  try {
    const formData = new FormData()
    formData.append('release_date', JSON.stringify(params.releaseDateAndTime))

    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${params.channelSlug}/launch_pannel`,
      method: ApiEndpoint.METHODS.POST,
      file: formData,
      authenticatable: true
    })
    if (response.status === 201) {
      return { isSuccess: true }
    }
    return { isSuccess: false, message: t`Unknown response status` }
  } catch (error: any) {
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
