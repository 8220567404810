/* eslint-disable react/no-danger */
import { t, Trans } from '@lingui/macro'
import React, { FC } from 'react'
import { ReturnValues } from '../../../../../api/useDataApi'
import { Loader } from '../../../../../components/Loader/Loader'
import { getTimePassed } from '../../../../../helpers/formatHelper'
import styles from './ArticleView.module.scss'
import { ArticleMeta } from 'meta/ArticleMeta'

import IconButtonRound, {
  RoundButtonType
} from 'components/buttons/IconButtonRound/IconButtonRound'
import EditIcon from 'assets/icons/EditIcon'
import { useSelector } from 'react-redux'
import { isUserChannelAdminSelector } from 'redux-store/user/user.selectors'
import useAdminAccess from 'hooks/AdminAccess'
import { useScrollToTop } from 'helpers/useScrollToTop'
import { getFormatedTextForStructuredPlainText } from 'helpers/getFormatedTextForStructuredPlainText'

interface ArticleViewProps {
  articleData: ReturnValues
  channelSlug: string
}
export const ArticleView: FC<ArticleViewProps> = ({
  articleData,
  channelSlug
}) => {
  const { isLoading, isError, data } = articleData
  const articleImage = data?.imageUrl
  const imageAltText = `Article.title.${t`Image`}`
  const aricleSlug = data?.id
  const isChannelAdmin = useSelector(isUserChannelAdminSelector)
  const adminAccess = useAdminAccess()
  const isAdmin = adminAccess.isAdmin || isChannelAdmin
  const formatedPremableText = getFormatedTextForStructuredPlainText(
    data?.preamble
  )
  useScrollToTop()
  const onEditClick = () => {
    const baseURL = `${process.env.REACT_APP_PROTOCOLL}${process.env.REACT_APP_SERVER_URL}/admin`
    const trailURL = `${aricleSlug}/edit?select_company=${channelSlug}`
    window.location.href = `${baseURL}/settings/playpage/posts/${trailURL}`
  }

  if (isError)
    return (
      <div className={styles.ArticleReadError} data-testid="read-article-error">
        <Trans>Something went wrong, Please try again later</Trans>
      </div>
    )
  return (
    <div
      className={styles.ArticlePageContainer}
      data-testid="article-container"
    >
      {data && data.title && (
        <ArticleMeta title={data?.title} imageUrl={articleImage} />
      )}
      {isLoading && !data && <Loader />}
      {isAdmin && (
        <div className={styles.EditButton}>
          <IconButtonRound
            buttonSize={'Medium'}
            type={RoundButtonType.Light}
            onClick={onEditClick}
          >
            <EditIcon />
          </IconButtonRound>
        </div>
      )}
      {data && data.published && (
        <div className={styles.ContentWrapper}>
          {articleImage && (
            <div className={styles.ImageContainerWrapper}>
              <div
                className={styles.ImageContainer}
                data-testid="article-image-container"
              >
                <img
                  alt={imageAltText}
                  className={styles.ArticleImage}
                  src={articleImage}
                />
              </div>
            </div>
          )}
          <div className={styles.ArticleBox}>
            <div className={styles.ArticleBody}>
              <div
                className={styles.Article}
                data-testid="article-text-container"
              >
                <div className={styles.DateText}>
                  {getTimePassed(data.published)}
                </div>
                <h1 className={styles.Title} data-testid="article-title">
                  {data.title}
                </h1>
                {formatedPremableText && (
                  <h2
                    className={styles.SubTitle}
                    data-testid="article-sub-title"
                  >
                    {formatedPremableText}
                  </h2>
                )}
                <div
                  className={styles.Content}
                  data-testid="article-content"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
