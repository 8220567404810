import React, { FC } from 'react'
import { TeamsContainer } from './TeamsContainer/TeamsContainer'
import { DateAndButtonsContainer } from './DateAndButtonsContainer/DateAndButtonsContainer'
import { GameScheduleCardType } from './gameScheduleType'
import { getButtonType } from './getButtonType'
import { getViewerChannelSlug } from './getViewerChannelSlug'
import { ChannelTypes } from '../../../pages/ChannelPage/channelType'
import { getIsStreamLive } from '../../../helpers/getIsStreamLive'
import styles from './GameScheduleCard.module.scss'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'

interface Props {
  content: GameScheduleCardType
  isAdmin: boolean
  channelSlug: string
  channelType: ChannelTypes
}
export const GameScheduleCard: FC<Props> = ({
  content,
  isAdmin,
  channelSlug,
  channelType
}) => {
  if (!content) return null
  const {
    label,
    labelSlug,
    id,
    hasBeenPlayed,
    homeTeam,
    awayTeam,
    showResult,
    startTime,
    highlightSlug
  } = content
  const now = Date.now() / 1000
  const upcomingGame = !hasBeenPlayed
  const pastGame = hasBeenPlayed
  const live = getIsStreamLive(content.startTime, content.endTime, now)

  const channel = useSelector(channelSelector)

  const channelRootDomain = channel?.data?.is_root_channel
    ? channel?.data?.root_domain
    : channel?.data?.root_channel?.root_domain
  const rootDomain =
    content.rootDomain && content.rootDomain !== channelRootDomain
      ? content.rootDomain
      : ''
  const channelSlugViewerPage = getViewerChannelSlug(
    channelSlug,
    channelType,
    content,
    rootDomain
  )
  return (
    <div className={styles.GameScheduleCard} data-testid="game-schedule-card">
      <div className={styles.LeftContainer}>
        <TeamsContainer
          hasBeenPlayed={hasBeenPlayed}
          homeTeamLogo={homeTeam.logo}
          homeTeamName={homeTeam.name}
          homeTeamScore={homeTeam.score}
          awayTeamLogo={awayTeam.logo}
          awayTeamName={awayTeam.name}
          awayTeamScore={awayTeam.score}
          isLive={live}
          gameSlug={`/${channelSlugViewerPage}/games/g/${id}`}
          showScore={showResult}
          hasLivestream={content.hasLivestream}
          rootDomain={rootDomain}
        />
      </div>
      <div
        className={styles.RightContainer}
        data-testid="game-schedule-card-right-continer"
      >
        <DateAndButtonsContainer
          label={label}
          id={id}
          labelSlug={labelSlug}
          higlightSlug={highlightSlug}
          channelSlug={channelSlugViewerPage}
          displayChannelSlug={content.displayChannelSlug}
          startTime={startTime}
          actionButtonType={getButtonType(
            isAdmin,
            content,
            live,
            pastGame,
            upcomingGame,
            channelType,
            channelSlug
          )}
          rootDomain={rootDomain}
        />
      </div>
    </div>
  )
}
