import React, { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import styles from './Theming.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { channelThemeSelector } from 'redux-store/channelTheme/channelTheme.selectors'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { SubmitButton } from 'components/buttons/SubmitButton/SubmitButton'
import { postThemeColors } from 'api/channel/settings/postThemeColors'
import { ColorSectionFields } from './ColorSectionFields'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { getColorPickerOptionsList } from './ColorPickerList'
import {
  StatusMessage,
  StatusMessageType
} from '../../StatusMessage/StatusMessage'
import { themeColorsValidationSchema } from './ThemingValidationSchema'
import { setChannelTheme } from 'redux-store/channelTheme/channelTheme.actions'
import { ThemeOptions } from 'redux-store/channelTheme/channelTheme.interface'
import { validateContrast } from 'helpers/calculateContrastRatio'

interface ThemingFormValues {
  topNavigationBackgroundColor: string
  topNavigationTextColor: string
  subNavigationBackgroundColor: string
  subNavigationTextColor: string
  subNavigationHighlightTextColor: string
  CTABackgroundColor: string
  CTATextColor: string
}
interface SectionWarning {
  sectionKey: string
  message: string
}
export const Theming: FC = () => {
  const channelTheme = useSelector(channelThemeSelector)
  const [colorRatioWarning, setColorRatioWarnigns] = useState<SectionWarning[]>(
    []
  )
  const warningList: SectionWarning[] = []
  const colorPickerOptions = getColorPickerOptionsList()
  const channel = useSelector(channelSelector)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const channelSlug = channel?.data?.subdomain

  const dispatch = useDispatch()
  const defaultCTAButtonColors = {
    background: '#ec1e24',
    text: '#ffffff'
  }

  const initialValues: ThemingFormValues = {
    topNavigationBackgroundColor:
      channelTheme.channelPrimaryBackgroundColorInverted || '#FFFFFF',
    topNavigationTextColor:
      channelTheme.channelPrimaryColorInverted || '#000000',
    subNavigationBackgroundColor:
      channelTheme.channelPrimaryBackgroundColor || '#FFFFFF',
    subNavigationTextColor: channelTheme.channelPrimaryColor || '#000000',
    subNavigationHighlightTextColor: channelTheme.channelPrimaryHighlightColor
      ? channelTheme.channelPrimaryHighlightColor
      : channelTheme.channelPrimaryColor || '#000000',
    CTABackgroundColor: channelTheme.channelCallToActionBackgroundColor
      ? channelTheme.channelCallToActionBackgroundColor
      : defaultCTAButtonColors.background,
    CTATextColor: channelTheme.channelCallToActionColor
      ? channelTheme.channelCallToActionColor
      : defaultCTAButtonColors.text
  }

  const validateSectionContrast = (
    sectionKey: string,
    backgroundColor: string,
    textColors: string[]
  ) => {
    const isValid = textColors.every((textColor) =>
      validateContrast(backgroundColor, textColor)
    )
    if (!isValid) {
      warningList.push({
        sectionKey,
        message: t`The contrast ratio between the background and text colors is below the WCAG minimum requirement of 4.5:1, it is recommended to change one of the colors to improve readability.`
      })
    }
  }
  const handleColorRatioValidation = (values: ThemingFormValues) => {
    validateSectionContrast(
      colorPickerOptions[0].key,
      values.topNavigationBackgroundColor,
      [values.topNavigationTextColor]
    )
    validateSectionContrast(
      colorPickerOptions[1].key,
      values.subNavigationBackgroundColor,
      [values.subNavigationTextColor, values.subNavigationHighlightTextColor]
    )
    validateSectionContrast(
      colorPickerOptions[2].key,
      values.CTABackgroundColor,
      [values.CTATextColor]
    )

    if (warningList.length > 0) {
      setColorRatioWarnigns(warningList)
    }
  }
  useEffect(() => {
    handleColorRatioValidation(initialValues)
  }, [])

  const onSubmit = async (
    values: ThemingFormValues,
    formikHelpers: FormikHelpers<ThemingFormValues>
  ) => {
    formikHelpers.setSubmitting(true)
    handleColorRatioValidation(values)
    const themeColors = {
      channelPrimaryColor: values.subNavigationTextColor,
      channelPrimaryColorInverted: values.topNavigationTextColor,
      channelPrimaryBackgroundColor: values.subNavigationBackgroundColor,
      channelPrimaryBackgroundColorInverted:
        values.topNavigationBackgroundColor,
      channelPrimaryHighlightColor: values.subNavigationHighlightTextColor,
      channelCallToActionBackgroundColor: values.CTABackgroundColor,
      channelCallToActionColor: values.CTATextColor
    }
    const response = await postThemeColors({ channelSlug, ...themeColors })
    if (response.isSuccess) {
      setShowSuccessMessage(true)

      dispatch(setChannelTheme(themeColors as ThemeOptions))
    }
    formikHelpers.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={themeColorsValidationSchema}
      validateOnChange
      onSubmit={onSubmit}
    >
      {(props: FormikProps<any>) => {
        const handleColorChange = (e: any) => {
          props.handleChange(e)
        }

        return (
          <Form>
            <div className={styles.Theming}>
              <div className={styles.InnerContainer}>
                {colorPickerOptions.map(
                  ({ key, sectionLabel, colorPickers }) => (
                    <ColorSectionFields
                      key={key}
                      sectionLabel={sectionLabel}
                      colorSelectorList={colorPickers}
                      formik={props}
                      onChange={handleColorChange}
                      warnginText={
                        colorRatioWarning.find(
                          (item) => item.sectionKey === key
                        )?.message
                      }
                    />
                  )
                )}
              </div>
              <SubmitButton
                type="secondary"
                disabled={!props.dirty || props.isSubmitting}
                label={t`Save changes`}
                customStyle={styles.SubmitButton}
              />
            </div>
            {showSuccessMessage && (
              <StatusMessage
                onClose={() => setShowSuccessMessage(false)}
                message={t`The theme colors have been successfully updated`}
                messageType={StatusMessageType.Success}
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
