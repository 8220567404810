import * as yup from 'yup'
import { t } from '@lingui/macro'

export const themeColorsValidationSchema = () => {
  const hexRegExp = /^#[0-9A-F]{6}$/i
  const hexColorValidation = yup
    .string()
    .matches(hexRegExp, t`please enter a valid hex color`)
    .required(t`Please enter a valid Hex color`)

  const notSameAs = (ref: string) =>
    hexColorValidation.notOneOf(
      [yup.ref(ref), null],
      t`This color cannot be the same as the background color`
    )

  return yup.object({
    topNavigationBackgroundColor: hexColorValidation,
    topNavigationTextColor: notSameAs('topNavigationBackgroundColor'),
    subNavigationBackgroundColor: hexColorValidation,
    subNavigationTextColor: notSameAs('subNavigationBackgroundColor'),
    subNavigationHighlightTextColor: notSameAs('subNavigationBackgroundColor'),
    CTABackgroundColor: hexColorValidation,
    CTATextColor: notSameAs('CTABackgroundColor')
  })
}
