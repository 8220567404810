import React, { FC } from 'react'

export const ColorPaletteIcon: FC = () => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.2796 24.7958C46.3531 26.7084 46.0625 28.0356 45.6152 28.9638C45.1701 29.8876 44.5262 30.5085 43.7301 30.9469C42.0292 31.8837 39.6288 32.0048 37.0214 31.9436C36.6357 31.9345 36.2378 31.9213 35.8395 31.9081C34.9895 31.8799 34.138 31.8517 33.4012 31.8636C32.3717 31.8802 31.1273 31.9661 30.1735 32.4876C29.6362 32.7813 29.1326 33.2431 28.8505 33.929C28.5771 34.5938 28.5868 35.2875 28.719 35.9187C28.9691 37.1134 29.7493 38.5059 30.9066 40.1223C31.9557 41.5877 32.2184 42.5297 32.2148 43.0393C32.2123 43.3993 32.0869 43.6566 31.6419 43.9133C31.1078 44.2214 30.1813 44.4579 28.8338 44.4806C27.5167 44.5028 25.9371 44.3184 24.2175 43.9209C20.7723 43.1244 16.9408 41.5137 13.7758 39.255C10.6167 37.0005 8.28056 34.2189 7.468 31.111C5.71501 20.998 12.854 11.3046 23.62 9.56576C34.3699 7.82952 44.4335 14.719 46.2796 24.7958Z"
      stroke="#2F3135"
      strokeWidth="3.375"
    />
    <circle cx="38.8125" cy="22.7188" r="2.8125" fill="#2F3135" />
    <circle cx="32.0625" cy="17.345" r="2.8125" fill="#2F3135" />
    <circle cx="23.0625" cy="17.345" r="2.8125" fill="#2F3135" />
    <circle cx="16.3125" cy="22.7188" r="2.8125" fill="#2F3135" />
  </svg>
)
