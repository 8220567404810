import { t } from '@lingui/macro'
import { ColorFieldConfig } from './ColorSectionFields'
const placeholder = t`Select a color`
interface ColorPickersOptionsList {
  sectionLabel: string
  key: string
  colorPickers: ColorFieldConfig[]
}
export const getColorPickerOptionsList = (): ColorPickersOptionsList[] => [
  {
    sectionLabel: t`Top navigation and footer`,
    key: 'section-1',
    colorPickers: [
      {
        id: 'topNavigationBackgroundColor',
        label: t`Background`,
        placeholder: placeholder
      },
      {
        id: 'topNavigationTextColor',
        label: t`Text color`,
        placeholder: placeholder
      }
    ]
  },
  {
    sectionLabel: t`Sub navigation`,
    key: 'section-2',
    colorPickers: [
      {
        id: 'subNavigationBackgroundColor',
        label: t`Background`,
        placeholder: placeholder
      },
      {
        id: 'subNavigationTextColor',
        label: t`Text color`,
        placeholder: placeholder
      },
      {
        id: 'subNavigationHighlightTextColor',
        label: t`Highlight text color`,
        placeholder: placeholder
      }
    ]
  },
  {
    sectionLabel: t`Call to action button`,
    key: 'section-3',
    colorPickers: [
      {
        id: 'CTABackgroundColor',
        label: t`Background`,
        placeholder: placeholder
      },
      {
        id: 'CTATextColor',
        label: t`Text color`,
        placeholder: placeholder
      }
    ]
  }
]
