import DATETIME from 'constants/dateTime.constants'
import { AccessRestrictionType } from '../../interfaces'
import { RootState } from '../store'
import { ViewType } from './stream.interface'

export const streamSelector = (state: RootState) => {
  return {
    ...state.stream,
    hasGame: !!state.stream?.data?.mediaObject?.game,
    canComment: !!state.stream?.data?.mediaObject?.enable_comments
  }
}

export const streamIsLoadingSelector = (state: RootState) =>
  state.stream.isLoading

export const streamAdminAccessSelector = (state: RootState) =>
  !!state.stream?.data?.streamUrl?.adminAccess

export const streamViewTypeSelector = (state: RootState): ViewType => {
  const { mediaObject } = state.stream?.data || {}
  const now = Math.floor(Date.now() / 1000)
  const isVideo = mediaObject?.type === 'video'
  const timeDelay = DATETIME.ONE_HOUR * 12
  const hasStarted = mediaObject?.live_start_at < now
  const hasEnded = mediaObject?.live_end_at < now
  const hasViewers = mediaObject?.view_count > 0
  if (
    hasStarted &&
    mediaObject?.live_end_at > now &&
    !mediaObject?.is_replay &&
    !mediaObject?.highlight_source &&
    !isVideo
  ) {
    return ViewType.LIVE
  }
  if (mediaObject?.is_replay) {
    return ViewType.REPLAY
  }
  if (mediaObject?.highlight_source) {
    return ViewType.HIGHLIGHT
  }
  if (isVideo) {
    return ViewType.VIDEO
  }
  if (!mediaObject?.live_start_at) {
    return ViewType.NO_LIVESTREAM
  }
  if (!hasStarted) return ViewType.UPCOMING
  if (
    hasEnded &&
    !mediaObject?.is_replay &&
    !mediaObject?.highlight_source &&
    !isVideo &&
    hasViewers
  ) {
    return ViewType.HAS_BEEN_BROADCASTED
  }
  if (
    mediaObject?.live_end_at <= now - timeDelay &&
    !mediaObject?.is_replay &&
    !mediaObject?.highlight_source &&
    !isVideo
  ) {
    return ViewType.NO_PAST_LIVESTREAM
  }
  return ViewType.UPCOMING
}

export const streamAccessRestrictionSelector = (
  state: RootState
): AccessRestrictionType => state.stream?.data?.mediaObject?.access_restriction

export const streamHasGeoblockSelector = (state: RootState) =>
  state?.stream.data.mediaObject?.geo_block?.countries?.length > 0
