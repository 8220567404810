import { RedirectApiResponse } from 'api/redirect/RedirectApiResponse'
import { getRedirect as getApiRedirect } from '../api/redirect/getRedirect'
import { getRedirect, RedirectData } from './getRedirect'

const doApiRedirectRequest = async (
  location: Location
): Promise<(RedirectApiResponse & RedirectData) | null> => {
  const { hostname, href, port } = location
  const domain = port !== '' ? `${hostname}:${port}` : hostname
  const result = await getApiRedirect(domain, href)
  if (!result) {
    return null
  }
  const releaseDate = result.releaseDate
  return {
    ...result,
    unixReleaseDate: releaseDate ? new Date(releaseDate).getTime() / 1000 : 0
  }
}

const doRedirect = (redirectUrl: string): void => {
  // Setting href reloads the window
  window.location.href = redirectUrl
}

export const redirectIfNeeded = async (): Promise<null> => {
  const noRedirectIfNotFound = location.href.includes('404')
  if (noRedirectIfNotFound) {
    return null
  }

  const redirectData = await doApiRedirectRequest(window.location)
  if (!redirectData) {
    return null
  }

  if (redirectData && redirectData.status.code === 404) {
    doRedirect('/404')
  }

  const redirectDetails = getRedirect({
    redirectData,
    currentDomain: location.hostname,
    development:
      location.port === '3000' && location.hostname !== 'prby.sportstage.se'
  })

  if (redirectDetails?.url && redirectDetails.url !== window.location.href) {
    doRedirect(redirectDetails.url)
  }

  return null
}
