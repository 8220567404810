import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getFilteredUpcomingStreams = async ({
  rootChannelSlug,
  page,
  perPageCount
}: {
  rootChannelSlug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/calendar_livestreams',
      params: {
        root_channel: rootChannelSlug,
        page: page,
        per_page: perPageCount
      }
    })
    const responseData = await resultResponse

    return responseData
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
