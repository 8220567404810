import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

export const getScheduleContentForChannel = async ({
  slug,
  page,
  perPageCount
}: {
  slug: string
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/livestreams',
      params: {
        per_page: perPageCount,
        page: page,
        company: slug
      }
    })
    const responseData = await resultResponse

    return responseData
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
