import { useCallback, useState } from 'react'
import { FeatureFlagsAvailable } from './FeatureFlagsAvailable'
import { getParsedFeatureFlags } from './getParsedFeatureFlags'

export type GetFlag = (flag: FeatureFlagsAvailable) => boolean
export type SetFlag = (flag: FeatureFlagsAvailable, value: boolean) => void

export const setFlag = (flag: FeatureFlagsAvailable, value: boolean) => {
  const key = flag.toString()
  if (value) {
    localStorage.setItem(key, 'true')
  } else {
    localStorage.removeItem(key)
  }
}

export const getFlag = (flag: FeatureFlagsAvailable) => {
  const key = flag.toString()
  return getParsedFeatureFlags()[key]
}

export const useFeatureFlags = () => {
  const [flags, setFlags] = useState(getParsedFeatureFlags())

  const cachedGetFlag = useCallback(
    (flag: FeatureFlagsAvailable) => {
      const key = flag.toString()
      return flags[key]
    },
    [flags]
  )

  const cachedSetFlag = useCallback(
    (flag: FeatureFlagsAvailable, value: boolean) => {
      const key = flag.toString()
      setFlag(flag, value)
      setFlags({ ...flags, [key]: value })
    },
    [flags]
  )

  return {
    flags,
    getFlag: cachedGetFlag,
    setFlag: cachedSetFlag
  }
}
