import ApiEndpoint, { ApiResponse } from '../../../network/ApiEndpoint'

export const getThemeColors = async (channelSlug: string) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: `companies/${channelSlug}/theme`,
      authenticatable: true
    })
    const responseData = await resultResponse

    return responseData
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
