import React, { FC } from 'react'
import { GameScheduleEventCardType } from 'components/cards/GameScheduleCard/gameScheduleType'
import { TimeContainer } from './TimeContainer/TimeContainer'
import styles from './GameCard.module.scss'
import { getButtonType } from 'components/cards/GameScheduleCard/getButtonType'
import { getIsStreamLive } from 'helpers/getIsStreamLive'
import { ActionButtonsSection } from './ActionButtons/ActionButtonsSection'
import classNames from 'classnames'
import { LinkSection } from './LinkSection/LinkSection'
import { TeamVsTeamSection } from './TeamVsTeamContainer/TeamVsTeamSection'
import { ChannelTypes } from 'pages/ChannelPage/channelType'

export enum GameCardBackground {
  Primary = 'primary',
  Secondary = 'secondary'
}
interface Props {
  game: GameScheduleEventCardType
  isAdmin: boolean
  channelSlug: string
  backgroundColor: GameCardBackground
  channelType: ChannelTypes
}

export const GameCard: FC<Props> = ({
  game,
  isAdmin,
  channelSlug,
  backgroundColor,
  channelType
}) => {
  const now = Date.now() / 1000
  const isLive = getIsStreamLive(game.startTime, game.endTime, now)
  const hasGameStarted = game.startTime < now
  const buttonType = getButtonType(
    isAdmin,
    game,
    isLive,
    game.hasBeenPlayed,
    !game.hasBeenPlayed,
    channelType,
    channelSlug
  )
  const idForThumbnail = `event-game-card-${game.homeTeam.slug}-vs-${game.awayTeam.slug}`

  return (
    <div
      className={classNames(styles.GameCardContainer, {
        [styles.Primary]: backgroundColor === GameCardBackground.Primary,
        [styles.Secondary]: backgroundColor === GameCardBackground.Secondary
      })}
      data-testid={idForThumbnail}
    >
      <TimeContainer startTime={game.startTime} isLive={isLive} />
      <div className={styles.InnerContainer}>
        <TeamVsTeamSection
          homeTeam={game.homeTeam}
          awayTeam={game.awayTeam}
          showScore={game.showResult}
          hasGameStarted={hasGameStarted}
        />
        <div className={styles.LinksButtonsContainer}>
          <LinkSection location={game.location} eventClass={game.eventClass} />
          <ActionButtonsSection
            type={buttonType}
            channelSlug={channelSlug}
            id={game.id}
            highlightsSlug={game.highlightSlug}
          />
        </div>
      </div>
    </div>
  )
}
