import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { FormModal } from 'features/sportData/FormModal/FormModal'
import { ReleaseDateForm } from './ReleaseDateForm'
interface Props {
  defaultOpen: boolean
  onClose: () => void
  onSuccess: (newReleaseDate: number) => void
}
export const ReleaseDateModal: FC<Props> = ({
  defaultOpen,
  onClose,
  onSuccess
}) => {
  return (
    <FormModal
      modalHeader={t`Changing to PoweredBy`}
      preHeader=""
      header={t`Release date`}
      modalInformation={t`You need to set a relase date to continue`}
      defaultOpen={defaultOpen}
      onClose={onClose}
    >
      <ReleaseDateForm onClose={onClose} onSuccess={onSuccess} />
    </FormModal>
  )
}
