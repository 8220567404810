import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { i18n } from '@lingui/core'

import RouterProvider from './providers/RouterProvider'
import store from './redux-store/store'
import { Loader } from './components/Loader/Loader'
import { ErrorMonitoring } from 'app/ErrorMonitoring'
import { BaseRoutes } from './components/app/BaseRoutes'
import { FeatureFlags } from './featureFlags/FeatureFlags'
import { I18nProvider } from '@lingui/react'
import { getCurrentLocale } from 'translations/i18n'
import { redirectIfNeeded } from 'redirect/redirectIfNeeded'

export const AppIndex = () => {
  getCurrentLocale()

  const [redirect, setRedirect] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const pathname = window.location.pathname
    if (pathname.includes('login/callback')) {
      setIsLoading(false)
    } else {
      redirectIfNeeded()
        .then((result) => {
          setRedirect(result)
          setIsLoading(false)
        })
        .catch((err) => {
          console.error('redirect err', err)
          setIsLoading(false)
        })
    }
  }, [location])

  if (isLoading) {
    return <Loader />
  }

  if (!redirect || redirect === null) {
    return (
      <ErrorMonitoring>
        <CookiesProvider>
          <I18nProvider i18n={i18n}>
            <Provider store={store}>
              <FeatureFlags />
              <BrowserRouter>
                <RouterProvider>
                  <BaseRoutes />
                </RouterProvider>
              </BrowserRouter>
            </Provider>
          </I18nProvider>
        </CookiesProvider>
      </ErrorMonitoring>
    )
  }
  return null
}
