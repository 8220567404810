/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { FC } from 'react'
import classNames from 'classnames'
import { t } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'

import FooterItemDropdown from './FooterItemDropdown/footerItemDropdown'
import { links } from './menuLinks'

import languageActions from '../../../../redux-store/language/language.actions'
import { getHasImpressumPage } from '../../../../pages/ChannelPage/Impressum/getHasImpressumPage'

import FooterLink, { LinkType } from '../../menuLink/MenuLink'
import { channelCountrySelector } from '../../../../redux-store/channel/channel.selectors'
import { getIsSpecialDomain } from 'getIsSpecialDomain'

import styles from './DefaultFooter.module.scss'

interface Locale {
  abbr: string
  name: string
}

interface Props {
  companyName: string
  languages: Locale[]
}

export const getCurrentYear = () => new Date().getFullYear()
export const DefaultFooter: FC<Props> = ({ companyName, languages }) => {
  const dispatch = useDispatch()

  const selectedLanguage = useSelector((state: any) => state.language)
  const isSolidsportDomain = getIsSpecialDomain(window.location.hostname)

  const channelCountry = useSelector(channelCountrySelector)

  const handleLanguageChange = (lang: string) => {
    dispatch(languageActions.setLanguage(lang))
  }

  const languageLabel = t`Language`

  const languagesList = languages.map((language: Locale) => ({
    name: language.name,
    value: language.abbr
  }))
  const impressumURL = process.env.REACT_APP_DEFAULT_IMPRESSUM_URL
  const hasImpressumPage = getHasImpressumPage(channelCountry?.code)
  const impressumPageLink = {
    type: LinkType.AnchorTag,
    link: `${impressumURL}`,
    translationText: 'Impressum'
  }

  const filteredLinks = isSolidsportDomain
    ? links
    : links.filter((link) => link.link !== '/calendar')

  const displayLinks = hasImpressumPage
    ? [...filteredLinks, impressumPageLink]
    : filteredLinks

  return (
    <div className={styles.Footer}>
      <div className={classNames(styles.FooterMenu, styles.TopRow)}>
        {displayLinks.map((link, index) => (
          <div
            data-testid="footer-top-row"
            key={`${link.translationText}__${index}`}
            className={classNames(
              styles.MenuItemContainer,
              styles.TopRowContainer
            )}
          >
            <div className={styles.MenuItem}>
              <FooterLink {...link} />
            </div>
          </div>
        ))}
      </div>

      <div className={classNames(styles.FooterMenu, styles.BottomRow)}>
        <div className={styles.MenuItemContainer}>
          <div className={classNames(styles.MenuItem, styles.DropDownButton)}>
            <FooterItemDropdown
              label={languageLabel}
              itemList={languagesList}
              onItemSelected={(item: string) => handleLanguageChange(item)}
              value={selectedLanguage}
            />
          </div>
        </div>
        <div className={classNames(styles.MenuItem, styles.CompanyName)}>
          &copy; {companyName} {getCurrentYear()}
        </div>
      </div>
    </div>
  )
}
