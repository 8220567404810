import React, { FC } from 'react'
import styles from './SubscriptionItem.module.scss'
import { getAutoRenewPeriodicityText } from 'pages/ChannelPage/Purchase/PurchaseOptions/CardText/getAutoRenewPeriodicityText'
import { Period } from 'pages/ChannelPage/Purchase/PurchaseOptions/CardText/period'
import { LinkTarget } from 'interfaces/LinkTarget.interface'
import { ExternalLinkIcon } from 'components/ChannelListItem/ExternalLinkIcon'
import { ScarfIcon } from 'pages/ChannelPage/Purchase/PurchaseOptions/Icons/ScarfIcon'
import Button, { ButtonType } from 'components/buttons/button/Button'
import { Trans, t } from '@lingui/macro'
import { Breakpoint, useCurrentBreakpoint } from 'hooks/CurrentBreakpoint'
import { Price } from 'interfaces/Price.interface'
import { getSubscriptionPrice } from 'pages/ChannelPage/Purchase/PurchaseContainer/getSubscriptionPrice'
import { useSelector } from 'react-redux'
import { preferredCurrencySelector } from 'redux-store/user/user.selectors'
import { getProductPeriodCost } from 'pages/ChannelPage/Purchase/PurchaseContainer/CardContent/getProductPeriodCost'

export interface UserSubscriptionItem {
  id: number
  title: string
  channelName: string
  channelSlug: string
  rootDomain?: string
  price: Price
  timeFrame: Period
  autoRenew: boolean
  type: string
  expireDate: string
}

interface SubscriptionItemProps {
  subscription: UserSubscriptionItem
  onCancelSubscription: (id: number) => void
}

export const SubscriptionItem: FC<SubscriptionItemProps> = ({
  subscription,
  onCancelSubscription
}) => {
  const {
    id,
    price,
    title,
    channelName,
    channelSlug,
    rootDomain,
    timeFrame,
    autoRenew,
    type,
    expireDate
  } = subscription
  const defaultDomain = `${process.env.REACT_APP_SERVER_URL}`
  const preferredCurrency = useSelector(preferredCurrencySelector)

  const currentBreakpoint = useCurrentBreakpoint()
  const isMobile = currentBreakpoint <= Breakpoint.sm
  const channelSlugLink = rootDomain
    ? `https://${rootDomain}/${channelSlug}`
    : `https://${defaultDomain}/${channelSlug}`

  const displayPrice = getSubscriptionPrice(
    price,
    preferredCurrency
  ).displayPrice
  const productPeriodCost = getProductPeriodCost(
    autoRenew,
    displayPrice,
    timeFrame
  )

  const getExpireOrRenewText = () => {
    if ((type == 'monthly_supporter' || type == 'subscription') && autoRenew) {
      return <Trans>Renews at {expireDate}</Trans>
    } else {
      return <Trans>Expires {expireDate}</Trans>
    }
  }

  return (
    <div className={styles.SubscriptionItem} data-testid="subscription-item">
      <div className={styles.Content}>
        <div className={styles.ScarfIcon}>
          <ScarfIcon />
        </div>
        <div className={styles.TopContainer}>
          <div className={styles.Title} data-testid="subscription-title">
            {title}
          </div>
          {isMobile && (
            <div
              className={styles.ProductPeriodCost}
              data-testid="product-period-cost"
            >
              {productPeriodCost}
            </div>
          )}
        </div>
        <div className={styles.expireDate}>{getExpireOrRenewText()}</div>
        <a
          href={channelSlugLink}
          target={LinkTarget.NewWindow}
          className={styles.ChannelLink}
        >
          {channelName}
        </a>
        {rootDomain && (
          <div className={styles.RootDomain}>
            {rootDomain}
            <ExternalLinkIcon />
          </div>
        )}
        {!isMobile && (
          <div
            className={styles.ProductPeriodCost}
            data-testid="product-period-cost"
          >
            {productPeriodCost}
          </div>
        )}
        {autoRenew && (
          <>
            <div className={styles.AutoRenewText}>
              <div>{getAutoRenewPeriodicityText(timeFrame)}</div>
              <div>
                <Trans>* Cancel, any time</Trans>
              </div>
            </div>
            <Button
              label={t`Cancel subscription`}
              type={ButtonType.GhostWithBoarder}
              onClick={() => onCancelSubscription(id)}
              buttonSize="Medium"
              roleType="button"
              customStyle={styles.CancelButton}
            />
          </>
        )}
      </div>
    </div>
  )
}
