import React, { FC } from 'react'
import styles from './ColorPicker.module.scss'
import { FormValidationError } from 'components/Forms/FormValidationError/FormValidationError'

interface Props {
  label: string
  id: string
  value: string
  placeholder: string
  isValid: boolean
  validationMessage: string
  readonly?: boolean
  onChange: (e: any) => void
}

export const ColorPicker: FC<Props> = ({
  label,
  id,
  value,
  placeholder,
  isValid,
  validationMessage,
  readonly,
  onChange
}) => {
  return (
    <div className={styles.ColorPickerContainer}>
      <input
        type="color"
        value={value}
        onChange={onChange}
        className={styles.ColorInput}
        id={id}
        disabled={readonly}
      />
      <div className={styles.FieldContainer}>
        <label className={styles.Label} htmlFor={id}>
          {label}
        </label>
        <input
          className={styles.ColorValueInput}
          value={value}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          disabled={readonly}
        />
        {!isValid && <FormValidationError errorMessage={validationMessage} />}
      </div>
    </div>
  )
}
