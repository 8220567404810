import React, { useCallback, useEffect, useState } from 'react'
import { t } from '@lingui/macro'

import { useDispatch, useSelector } from 'react-redux'
import {
  CustomDomainStatus,
  getCustomDomainStatus
} from '../../../../api/channel/settings/getCustomDomainStatus'
import { channelSelector } from '../../../../redux-store/channel/channel.selectors'
import { DomainRegistrationStatus } from './CustomDomain/DomainStatus/DomainRegistrationStatus'

import { BrandingHeader } from './BrandingHeader/BrandingHeader'
import { Divider } from '../../../../components/page/header/components/hamburgerMenuContent/divider/Divider'
import { Accordion } from '../../../../components/Accordion/Accordion'
import { AccordionTheme } from '../../../../components/Accordion/AccordionItem/AccordionItem'

import { Loader } from '../../../../components/Loader/Loader'

import { ProductTypeID } from './BrandingHeader/ProductTypes'
import { getBrandingAccordionList } from './BrandingAccordionList'
import { CustomUrlIcon } from './CustomDomain/CustomUrlIcon'
import { CustomDomain } from './CustomDomain/CustomDomain'
import { useDataApi } from 'api/useDataApi'
import { getThemeColors } from 'api/channel/settings/getThemeColors'
import { ThemeOptions } from 'redux-store/channelTheme/channelTheme.interface'
import { setChannelTheme } from 'redux-store/channelTheme/channelTheme.actions'
import { getLaunchPanelData } from 'api/channel/settings/getLaunchPanelData'
import channelsActions from 'redux-store/channel/channels.actions'
import dayjs from 'dayjs'

export const Branding = () => {
  const initialStatus: CustomDomainStatus = {
    status: DomainRegistrationStatus.NotSet,
    customDomain: ''
  }
  const [status, setStatus] = useState(initialStatus)
  const [domainStatusIsLoading, setDomainStatusIsLoading] = useState(false)
  const dispatch = useDispatch()
  const channel = useSelector(channelSelector)
  const channelSlug = channel.data?.subdomain

  const { data, isLoading } = useDataApi({
    apiCall: useCallback(getLaunchPanelData, []),
    parameters: channelSlug
  })
  const releasDate = data?.data?.release_date

  useEffect(() => {
    if (data?.data?.channel_variant) {
      dispatch(
        channelsActions.updateProductType(
          data?.data?.channel_variant.toString()
        )
      )
    }
  }, [data?.data?.channel_variant])

  useEffect(() => {
    if (releasDate) {
      const date = dayjs(releasDate).unix()
      dispatch(channelsActions.updateReleaseDate(date))
    }
  }, [releasDate])
  const channelProductType = channel?.data?.productVariant?.id

  const handleCheckDomain = useCallback(async () => {
    setDomainStatusIsLoading(true)
    const domainStatus = await getCustomDomainStatus(channelSlug)
    setStatus(domainStatus)
    setDomainStatusIsLoading(false)
  }, [channelSlug])

  useEffect(() => {
    if (channelSlug) {
      handleCheckDomain()
    }
  }, [handleCheckDomain, channelSlug])

  const { data: themeData } = useDataApi({
    apiCall: useCallback(getThemeColors, []),
    parameters: channelSlug
  })
  const theme: ThemeOptions = themeData?.data

  useEffect(() => {
    if (theme && theme.channelPrimaryBackgroundColor) {
      dispatch(setChannelTheme(theme))
    }
  }, [theme])

  if (!channel.data) return <Loader />

  const staticAccordionList = getBrandingAccordionList(channelSlug)

  const accordionList =
    !domainStatusIsLoading && status.customDomain !== ''
      ? [
          {
            id: 'custom-url',
            headerText: t`Custom URL`,
            icon: CustomUrlIcon,
            children: (
              <CustomDomain
                onCheckDomain={handleCheckDomain}
                domainStatus={status}
                channelSlug={channelSlug}
              />
            )
          },
          ...staticAccordionList
        ]
      : staticAccordionList
  if (isLoading) {
    return <Loader />
  }
  return (
    <div>
      <BrandingHeader />
      <Divider />
      {channelProductType === ProductTypeID.PoweredBy && (
        <Accordion
          theme={AccordionTheme.White}
          items={accordionList}
          initialOpenItems={[]}
        />
      )}
    </div>
  )
}
