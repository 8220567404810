export const calculateContrastRatio = (hex1: string, hex2: string): number => {
  const getLuminance = (hex: string): number => {
    const rgb = parseInt(hex.slice(1), 16)
    const r = (rgb >> 16) & 0xff
    const g = (rgb >> 8) & 0xff
    const b = rgb & 0xff

    const normalize = (value: number) => {
      const s = value / 255
      return s <= 0.03928 ? s / 12.92 : Math.pow((s + 0.055) / 1.055, 2.4)
    }
    return 0.2126 * normalize(r) + 0.7152 * normalize(g) + 0.0722 * normalize(b)
  }

  const lum1 = getLuminance(hex1)
  const lum2 = getLuminance(hex2)
  return lum1 > lum2
    ? (lum1 + 0.05) / (lum2 + 0.05)
    : (lum2 + 0.05) / (lum1 + 0.05)
}

export const validateContrast = (
  hex1: string,
  hex2: string,
  minContrastRatio: number = 4.5
) => {
  const contrastRatio = calculateContrastRatio(hex1, hex2)
  return contrastRatio >= minContrastRatio
}
