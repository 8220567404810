import React, { FC } from 'react'
import { RoundIconButton, Scheme } from '../../buttons/RoundIconButton'
import styles from './LinkThumbnail.module.scss'

interface Props {
  label: string
  link: string
  backgroundImage: string
}
export const LinkThumbnail: FC<Props> = ({ label, link, backgroundImage }) => {
  return (
    <a href={link}>
      <div className={styles.LinkThumbnail}>
        <img src={backgroundImage} alt={label} />
        <div className={styles.GradientContainer}>
          <div className={styles.BottomContainer}>
            <h3 className={styles.Label}>{label}</h3>

            <div className={styles.Button}>
              <RoundIconButton
                newClassName={Scheme.Dark}
                iconSize="medium"
                arrowDirection="right"
              />
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}
