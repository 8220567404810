import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { ActionButtons } from './GameScheduleButtons/GameScheduleButtons'
import { GameScheduleCardType } from './gameScheduleType'
import { canPrepareLivestream } from './canPrepareLivestream'

export const getButtonType = (
  isAdmin: boolean,
  content: GameScheduleCardType,
  live: boolean,
  pastGame: boolean,
  upcomingGame: boolean,
  channelType: ChannelTypes,
  channelSlug: string
) => {
  const {
    hasLivestream,
    hasReplay,
    highlightSlug,
    startTime,
    homeTeam,
    awayTeam
  } = content

  const showPrepareLivestreamButton = canPrepareLivestream(
    channelSlug,
    homeTeam.channelSlug || '',
    awayTeam.channelSlug || '',
    channelType,
    startTime
  )
  if (isAdmin) {
    if (hasLivestream) {
      if (live) {
        return ActionButtons.AdminLive
      }
      if (pastGame) {
        if (highlightSlug && hasReplay) {
          return ActionButtons.HighlightAndReplay
        }
        return ActionButtons.AdminPastGame
      }

      return ActionButtons.AdminPlanned
    }
    if (pastGame) {
      return ActionButtons.AdminPastGameNoBroadcasted
    }
    return showPrepareLivestreamButton
      ? ActionButtons.AdminNotPlanned
      : ActionButtons.NoLivestream
  }
  if (pastGame) {
    if (hasLivestream) {
      if (highlightSlug && hasReplay) {
        return ActionButtons.HighlightAndReplay
      }
      return ActionButtons.Replay
    }
    return ActionButtons.NoLivestream
  }
  if (upcomingGame) {
    if (hasLivestream) {
      if (live) {
        return ActionButtons.Live
      }
      return ActionButtons.UpcomingLivestream
    }
  }
  return ActionButtons.NoLivestream
}
