import React, { FC } from 'react'
import { t, Trans } from '@lingui/macro'

import styles from './DefaultError.module.scss'
import ContentContainer from 'components/ContentContainer'
import Button, { ButtonType } from 'components/buttons/button/Button'

export const DefaultError: FC = () => {
  return (
    <ContentContainer
      hasLeftRightPadding
      hasTopBottomPadding
      hasLightBackground
    >
      <div className={styles.error}>
        <h3>We are sorry, but something went wrong.</h3>
        <p>
          <Trans>
            We are unable to display the desired content at this time. Please
            try again.
          </Trans>
        </p>

        <Button
          onClick={() => {
            window.location.href = '/'
          }}
          label={t`Go to start page`}
          type={ButtonType.Secondary}
          buttonSize="Medium"
        />
      </div>
    </ContentContainer>
  )
}
