import { ChannelTypes } from 'pages/ChannelPage/channelType'
import { isWithin24Hours } from './isWithin24Hours'

export const canPrepareLivestream = (
  channelSlug: string,
  homeTeamSlug: string,
  awayTeamSlug: string,
  channelType: ChannelTypes,
  startTime: number
) => {
  const isHomeTeam = homeTeamSlug === channelSlug
  const isAwayTeam = awayTeamSlug === channelSlug
  const isTeamChannel = ChannelTypes.team === channelType
  const noTeamMatchSlug = ![homeTeamSlug, awayTeamSlug].includes(channelSlug)
  const awayTeamCanActivate = isWithin24Hours(startTime) && isAwayTeam
  return isTeamChannel && (noTeamMatchSlug || isHomeTeam || awayTeamCanActivate)
}
