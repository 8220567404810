import DATETIME from 'constants/dateTime.constants'
import dayjs from 'dayjs'

export const isWithin24Hours = (timestamp: number) => {
  const currentTime = dayjs(Date.now()).unix()
  const timeDifference = timestamp - currentTime
  const oneDay = DATETIME.ONE_DAY

  return timeDifference > 0 && timeDifference <= oneDay
}
