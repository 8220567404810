const CHANNEL = {
  LOADING_CHANNEL: 'LOADING_CHANNEL',
  CHANNEL_LOADED: 'CHANNEL_LOADED',
  LOADING_CHANNEL_ERROR: 'LOADING_CHANNEL_ERROR',
  UPDATE_COVER_IMAGE: 'CHANNEL_UPDATE_COVER_IMAGE',
  UPDATE_LOGO_IMAGE: 'CHANNEL_UPDATE_LOGO_IMAGE',
  INCREMENT_FOLLOWERS: 'INCREMENT_FOLLOWERS',
  DECREMENT_FOLLOWERS: 'DECREMENT_FOLLOWERS',
  SHOULD_CHANGE: 'SHOULD_CHANGE',
  UPDATE_PRODUCT_TYPE: 'UPDATE_PRODUCT_TYPE',
  UPDATE_RELEASE_DATE: 'UPDATE_RELEASE_DATE'
}

export default CHANNEL
