import ApiEndpoint, { ApiResponse } from '../../network/ApiEndpoint'

// https://solidsport.com/api/play_v1/timeline_objects/collections/calendar_livestreams?page=1&per_page=20

export const getAllUpcomingVideos = async ({
  page,
  perPageCount
}: {
  page: number
  perPageCount: number
}) => {
  try {
    const resultResponse: ApiResponse = await ApiEndpoint.call({
      path: 'timeline_objects/collections/calendar_livestreams',
      params: {
        page: page,
        per_page: perPageCount
      }
    })
    const responseData = await resultResponse

    return responseData
  } catch (e: any) {
    // @ts-ignore
    throw new Error(e.message)
  }
}
