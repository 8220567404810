import React, { FC } from 'react'
import styles from './Theming.module.scss'
import { ColorPicker } from 'components/ColorPicker/ColorPicker'
import { Field, FormikProps } from 'formik'
export interface ColorFieldConfig {
  id: string
  label: string
  placeholder: string
  readOnly?: boolean
}
interface Props {
  sectionLabel: string
  formik: FormikProps<any>
  colorSelectorList: ColorFieldConfig[]
  onChange: (e: any) => void
  warnginText?: string
}

export const ColorSectionFields: FC<Props> = ({
  sectionLabel,
  formik,
  colorSelectorList,
  onChange,
  warnginText
}) => {
  return (
    <div className={styles.ColorSection}>
      <div className={styles.SectionLabel}>{sectionLabel}</div>
      {warnginText && <div>{warnginText}</div>}
      <div className={styles.Fields}>
        {colorSelectorList.map(({ id, label, placeholder, readOnly }) => (
          <Field
            key={id}
            component={ColorPicker}
            id={id}
            value={formik.values[id]}
            isValid={!formik.errors[id]}
            validationMessage={formik.errors[id]}
            placeholder={placeholder}
            label={label}
            onChange={(e: any) => onChange(e)}
            readonly={readOnly}
          />
        ))}
      </div>
    </div>
  )
}
