import { getIsSpecialDomain } from '../getIsSpecialDomain'
import { FeatureFlagsAvailable } from '../featureFlags/FeatureFlagsAvailable'
import { setFlag } from 'featureFlags/useFeatureFlags'

export type RedirectData = {
  redirect: boolean
  redirectUrl: string | null
  redirectChannelRootDomain: string | null
  redirectChannelSubdomain: string | null
  unixReleaseDate: number | null
}

export type RedirectResult = {
  url: string | null
  isPrerelease: boolean
}

type Params = {
  redirectData: RedirectData
  currentDomain: string
  now?: number
  development?: boolean
}

export const getRedirect = ({
  redirectData,
  currentDomain,
  now = Date.now() / 1000,
  development = false
}: Params): RedirectResult | null => {
  const isSpecialDomain = getIsSpecialDomain(currentDomain)
  const channelReleaseDate = redirectData.unixReleaseDate

  const isBeforeChannelRelease = channelReleaseDate
    ? channelReleaseDate >= now
    : false

  // poweredby and before release
  if (isBeforeChannelRelease && !isSpecialDomain) {
    setFlag(FeatureFlagsAvailable.ComingSoon, false)
    return {
      isPrerelease: isBeforeChannelRelease,
      url: redirectData.redirectUrl
    }
  }

  // no redirect
  if (!redirectData.redirect) {
    return null
  }

  // if is solidport and before release, dont redirect and
  if (isSpecialDomain && isBeforeChannelRelease) {
    setFlag(FeatureFlagsAvailable.ComingSoon, false)
    return {
      isPrerelease: false,
      url: redirectData.redirectUrl
    }
  }

  if (development) {
    return null
  }

  // if we need to redirect and there is no key,
  // set the key and provide redirect detatils
  if (redirectData.redirect) {
    const { redirectUrl } = redirectData

    return {
      url: redirectUrl,
      isPrerelease: isBeforeChannelRelease
    }
  }

  return null
}
