import { t } from '@lingui/macro'
import ApiEndpoint, { ApiResponse } from 'network/ApiEndpoint'
export interface PostThemeColorsRequest {
  channelSlug: string
  channelPrimaryColor: string
  channelPrimaryColorInverted: string
  channelPrimaryBackgroundColor: string
  channelPrimaryBackgroundColorInverted: string
  channelPrimaryHighlightColor: string
  channelCallToActionBackgroundColor: string
  channelCallToActionColor: string
}
export const postThemeColors = async (params: PostThemeColorsRequest) => {
  try {
    const formData = new FormData()
    formData.append('channel_primary_color', params.channelPrimaryColor)
    formData.append(
      'channel_primary_color_inverted',
      params.channelPrimaryColorInverted
    )
    formData.append(
      'channel_primary_background_color',
      params.channelPrimaryBackgroundColor
    )
    formData.append(
      'channel_primary_background_color_inverted',
      params.channelPrimaryBackgroundColorInverted
    )
    formData.append(
      'channel_primary_highlight_color',
      params.channelPrimaryHighlightColor
    )
    formData.append(
      'channel_call_to_action_background_color',
      params.channelCallToActionBackgroundColor
    )
    formData.append(
      'channel_call_to_action_color',
      params.channelCallToActionColor
    )
    const response: ApiResponse = await ApiEndpoint.call({
      path: `companies/${params.channelSlug}/theme`,
      method: ApiEndpoint.METHODS.POST,
      file: formData,
      authenticatable: true
    })
    if (response.status === 201) {
      return { isSuccess: true }
    }
    return { isSuccess: false, message: t`Unknown response status` }
  } catch (error: any) {
    console.error('error!', error)
    if (error?.status === 400 && error?.message) {
      return { isSuccess: false, message: error.message }
    }
    return { isSuccess: false, message: t`Unknown error` }
  }
}
