import React from 'react'
import { AlertType } from 'features/viewerPage/components/StreamMessage/getAlertType'
import classNames from 'classnames'
import styles from './AlertMessage.module.scss'
import { MessageIcon } from 'features/viewerPage/components/StreamMessage/MessageIcon'

/**
 * @typedef {object} AlertMessageProps'
 */
type AlertMessageProps = {
  visible: boolean
  level: AlertType
  title: string
  text: string
}
export const AlertMessage: React.FC<AlertMessageProps> = ({
  visible,
  level,
  title,
  text
}) =>
  visible ? (
    <div className={classNames(styles.AlertMessage, styles[level])}>
      <MessageIcon alertType={level} />
      <div className={styles.TextContent}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Text}>{text}</div>
      </div>
    </div>
  ) : null
