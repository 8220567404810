import React, { FC, useState } from 'react'

import { t } from '@lingui/macro'
import { DateInputField } from 'components/Forms/DateInputField/DateInputField'
import { FormActionButtons } from 'components/Forms/FormActionButtons/FormActionButtons'
import { TimeField } from 'components/Forms/TimeField/TimeField'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import styles from './ReleaseDateForm.module.scss'
import { postReleaseDate } from 'api/channel/settings/postReleaseDate'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { formatUnixDate, formatUnixTime } from 'helpers/formatHelper'
import channelsActions from 'redux-store/channel/channels.actions'
interface ReleaseDateValues {
  releaseDate: string
  releaseTime: string
}
interface Props {
  onClose: () => void
  onSuccess: (newReleaseDate: number) => void
}

export const ReleaseDateForm: FC<Props> = ({ onClose, onSuccess }) => {
  const channel = useSelector(channelSelector).data
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const channelSlug = channel?.subdomain
  const releaseDate = channel?.release_date
  const initialValues: ReleaseDateValues = {
    releaseDate: releaseDate ? formatUnixDate(releaseDate) : '',
    releaseTime: releaseDate ? formatUnixTime(releaseDate) : ''
  }
  const onSubmit = async (
    values: ReleaseDateValues,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true)
    setIsLoading(true)
    const combinedReleaseDateTime = dayjs(
      `${values.releaseDate} ${values.releaseTime}`,
      'YYYY-MM-DD HH:mm'
    )
    const response = await postReleaseDate({
      channelSlug: channelSlug,
      releaseDateAndTime: combinedReleaseDateTime.unix()
    })
    if (response.isSuccess) {
      dispatch(
        channelsActions.updateReleaseDate(combinedReleaseDateTime.unix())
      )
      onSuccess(combinedReleaseDateTime.unix())
      onClose()
    } else {
      formikHelpers.setFieldError(values.releaseDate, response.message)
    }
    setIsLoading(false)
    formikHelpers.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur
      onSubmit={onSubmit}
      r
    >
      {(props: FormikProps<any>) => (
        <Form>
          <div className={styles.Container}>
            <Field
              component={DateInputField}
              id="releaseDate"
              name="releaseDate"
              label={t`Date`}
              placeholder={t`yyyy-mm-dd`}
              onChange={props.handleChange}
              dateValue={props.values.releaseDate}
              isValid={!props.errors.releaseDate}
              validationMessage={props.errors.releaseDate}
              isRequired={false}
            />
            <Field
              component={TimeField}
              id="releaseTime"
              name="releaseTime"
              label={t`Time`}
              placeholder={t`hh:mm`}
              onChange={props.handleChange}
              timeValue={props.values.releaseTime}
              isValid={!props.errors.releaseTime}
              validationMessage={props.errors.releaseTime}
              isRequired={false}
            />
            <FormActionButtons
              formik={props}
              cancelButtonLabel={t`Cancel`}
              submitButtonLabel={isLoading ? t`Saving...` : t`Save date`}
              onCancel={onClose}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}
