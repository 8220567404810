import { ProductTypeID } from 'pages/ChannelPage/ChannelSettings/Branding/BrandingHeader/ProductTypes'
import CHANNEL from './channel.constants'

const getChannel = (slug: string) => ({
  type: CHANNEL.LOADING_CHANNEL,
  id: slug
})

const setShouldChange = () => ({
  type: CHANNEL.SHOULD_CHANGE
})

const getChannelSuccess = (data: any) => ({
  type: CHANNEL.CHANNEL_LOADED,
  data
})

const getChannelError = (error: any) => ({
  type: CHANNEL.LOADING_CHANNEL_ERROR,
  error
})

const updateLogoImage = (imagePath: string) => ({
  type: CHANNEL.UPDATE_LOGO_IMAGE,
  imagePath
})

const updateCoverImage = (imagePath: string) => ({
  type: CHANNEL.UPDATE_COVER_IMAGE,
  imagePath
})

const incrementFollowerCount = () => ({
  type: CHANNEL.INCREMENT_FOLLOWERS
})
const decrementFollowerCount = () => ({
  type: CHANNEL.DECREMENT_FOLLOWERS
})

const channelCompanyActionCreator = (payload: any) => {
  return (disp: any) => {
    disp(getChannelSuccess(payload))
  }
}
const updateReleaseDate = (releaseDate: number) => ({
  type: CHANNEL.UPDATE_RELEASE_DATE,
  releaseDate
})
const updateProductType = (productTypeID: ProductTypeID) => ({
  type: CHANNEL.UPDATE_PRODUCT_TYPE,
  productTypeID
})

export default {
  channelCompanyActionCreator,
  getChannel,
  getChannelSuccess,
  getChannelError,
  updateLogoImage,
  updateCoverImage,
  incrementFollowerCount,
  decrementFollowerCount,
  setShouldChange,
  updateReleaseDate,
  updateProductType
}
