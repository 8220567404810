import React from 'react'
import { t } from '@lingui/macro'
import { AccordionItem } from 'components/Accordion/AccordionItem/AccordionItem'
import { Logos } from './Logos/Logos'
import { LogosIcon } from './Logos/LogosIcon'
import { FontSettingsIcon } from './Fonts/FontSettingsIcon'
import { CustomFonts } from './Fonts/CustomFonts'
import { LinkIcon } from './CustomLinks/LinkIcon'
import { CustomLinks } from './CustomLinks/CustomLinks'
import { Theming } from './Theming/Theming'
import { ColorPaletteIcon } from 'assets/icons/ColorPaletteIcon'

export const getBrandingAccordionList = (
  channelSlug: string
): AccordionItem[] => [
  {
    id: 'logos',
    headerText: t`Logos`,
    icon: LogosIcon,
    children: <Logos channelSlug={channelSlug} />
  },
  {
    id: 'theme',
    headerText: t`Theming`,
    icon: ColorPaletteIcon,
    children: <Theming />
  },
  {
    id: 'custom-links',
    headerText: t`Custom links`,
    icon: LinkIcon,
    children: <CustomLinks channelSlug={channelSlug} />
  },
  {
    id: 'custom-fonts',
    headerText: t`Fonts`,
    icon: FontSettingsIcon,
    children: <CustomFonts channelSlug={channelSlug} />
  }
]
